import React from 'react';
import { useEffect, useState, useRef } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';

import { PrimaryButtonForm } from 'shared/styles/buttons';
import { IconStyle } from 'components/SharedComponents/ModalInformation/styles';
import { Toast } from 'primereact/toast';
import { MessageOptaPlanner } from 'components/DashboardComponents/Scheduler/styles';

import { ModalContainer, } from 'shared/styles/constants/tagsStyles';
import { AUTH, LOGIN } from 'utils/constants/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getEmail, getUserName } from 'utils/localStorage/user';
import { logout } from 'utils/api/SessionApi';

const SessionWorning = ({
  fullBind,
  setModalValue,
  values,
  type,
  title,
  subTitle,
  icon,
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
const history = useHistory()
  const toast = useRef();
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: '',
    });
  }, [fullBind]);


  const clearTokenAndRedirect = async () => {
    // await logout(getUserName(), getEmail());
    localStorage.clear();
    history.push(AUTH + LOGIN);
  };



  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <ModalContainer min_width={'40%'} max_width={'50%'}>
        <ModalDynamic {...addFullBind} loading={false}>
          <IconStyle type={type}>{icon}</IconStyle>
          <MessageOptaPlanner className={'mt-4 mb-3'}>
            <div className={'warningMessage ps-3 pe-3'}>
              <h5>
                {subTitle}
              </h5>
            </div>
          </MessageOptaPlanner>
          <div className="d-flex justify-content-center modal-footer border-0">
            {/* <CancelButton
              type="button"
              className="btn btn-secondary"
              onClick={() => setModalValue(false)}>
              NO, CANCEL
            </CancelButton> */}

            <PrimaryButtonForm
              onClick={e => {
                clearTokenAndRedirect()
              }}>
              Sign In
            </PrimaryButtonForm>
          </div>
        </ModalDynamic>
      </ModalContainer>
    </div>
  );
};
export default SessionWorning;



