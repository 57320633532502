module.exports = {
  InvalidOrEmptyValues:
    'Some of the fields have invalid or empty values. Please review.',
  ERROR: 'Error',
  SAVE: 'Save',
  SAVED: 'Saved',
  FINISH: 'Finish',
  SkillAdded: 'The skill has already been added.',
  DetailsNotCreated: 'Personal Details have not been created.',
  InvalidRange: 'Invalid range',
  SERVER_ERROR: "Something went wrong. Please try again later.",
  BAD_GATEWAY_ERROR: "Our servers are updating. Please wait a few minutes.",
  noPermissionMessage : "You don't have permission to access the information.",
  SESSION : "Your session has been expired, login to continue"
};
