import { apiLoginURL, apiLoginURLV2, apiLoginURLV3 } from '../env';
import { methods, headers, unauthorized_status_code } from 'utils/constants/api';
import { post } from './Api';
import { getToken } from '../localStorage/token';

const { POST, GET } = methods;

export const getUser = async username => {
  const url = `${apiLoginURLV2}/users/${username}/?view=Web`;

  const response = await fetch(url, {
    headers: {
      ...headers,
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === unauthorized_status_code) {
    return false;
  }
  const json = await response.json();
  return json;
};

export const login = async (email, password) => {
  const url = `${apiLoginURLV3}/users/login/?view=Web`;

  const body = {
    email,
    password,
    view: "Web"
  };

  const response = await fetch(url, {
    method: POST,
    headers: {
      ...headers,
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });

  const json = await response.json();

  // if (response.status !== 200) throw json;

  return json;
};

export const logout = async (username, email) => {
  const url = `${apiLoginURLV3}/users/logout/?view=Web`;

  const body = {
    username,
    email,
    view: "Web"
  };

  return post(url, body, POST);
};

export const forgotPassword = async email => {
  const url = `${apiLoginURL}/users/forgot/?view=Web`;

  const body = {
    email,
  };

  const response = await fetch(url, {
    method: POST,
    headers: {
      ...headers,
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });

  const json = await response.json();

  // if (response.status !== 200) throw json;

  return json;
};

export const verifyEmail = async ({ token, password }) => {
  const url = `${apiLoginURL}/users/verifyEmail/?view=Web`;

  const body = {
    token,
    password,
  };

  const response = await fetch(url, {
    method: POST,
    headers: {
      ...headers,
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });

  const json = await response.json();

  // if (response.status !== 200) throw json;

  return json;
};

export const checkToken = async ({ token }) => {
  const url = `${apiLoginURL}/users/validateVerifyEmail/?token=${token}&view=Web`;


  const response = await fetch(url, {
    method: GET,
    headers: {
      ...headers,
      Accept: 'application/json',
    },
  });

  const json = await response.json();

  // if (response.status !== 200) throw json;

  return json;
};

export const verifyPin = async (token, password, password_confirmation) => {
  const url = `${apiLoginURL}/users/forgotMobilePassword/?view=Web`;

  const body = {
    token,
    password,
    password_confirmation
  };

  const response = await fetch(url, {
    method: POST,
    headers: {
      ...headers,
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });

  const json = await response.json();

  //if (response.status !== 200) throw json;

  return json;
};

export const VerifyOTPApi = async (userId, OTP, token) => {
  const url = `${apiLoginURLV2}/users/${userId}/otpAuthentication/?view=Web`;

  const body = {
    otp: OTP
  };

  const response = await fetch(url, {
    method: POST,
    headers: {
      ...headers,
      Accept: 'application/json',
      Authorization: 'Token ' + token,
    },
    body: JSON.stringify(body),
  });

  const json = await response.json();

  return json;
};

export const resendOTPApi = async (userId, token) => {
  const url = `${apiLoginURLV2}/users/${userId}/resendOTP/?view=Web`;

  const response = await fetch(url, {
    method: GET,
    headers: {
      ...headers,
      Accept: 'application/json',
      Authorization: 'Token ' + token,
    }
  });

  const json = await response.json();

  return json;
};