import React from 'react';
import Image from 'components/SharedComponents/Image';

import {EmployeeSelectedContainer, ClientInfo, UserImg} from './styles';

const RowInfoModel = ({noPhoto = false, photoRow = '', rows = [], name = '', lastName = '', dateOfBirth='',popupStyle= false, circleImage , Center = false}) => {
  return (
    <EmployeeSelectedContainer 
      className={`d-flex  ${popupStyle ? "justify-content-end" : Center ? "justify-content-center":"" }`} 
      style={popupStyle ? {marginRight:'-100px'} : {}}>
      {noPhoto ? null : <UserImg>
        <Image
          src={photoRow}
          name={name}
          lastName={lastName}
          alt={'user-img'}
          fontSize={'0.9rem'}
        />
      </UserImg>}

      {rows.map((row, index) => {
        const { style = {} } = row;
        return (
          <ClientInfo style={{ ...style }} key={index}>
            {row.label}
            <div>{row.value}</div>
          </ClientInfo>
        );
      })}
    </EmployeeSelectedContainer>
  );
};

export default RowInfoModel;
