import styled, { keyframes } from 'styled-components';
import {
  ROTA_AVAILABLE,
  ROTA_SHIFT,
  ERR_COLOR_800,
  PURPLE,
  SOFT_RED,
} from 'shared/styles/constants/colors';

import {MD,LG} from 'shared/styles/constants/resolutions';

export const SchedulerContainer = styled.div`
  height: ${props=>props.height ? props.height : '500px'};
  opacity:${props=>props.loading?'0.4':"1"};
  pointer-events:${props=>props.loading?"none":"auto"};
  overflow-x: 'hidden';
`;

export const MessageOptaPlanner = styled.div`
  text-align: center;
  .assigned {
    .badge-primary {
      background: ${ROTA_AVAILABLE};
      padding: 0.3rem 1.5rem;
    }
  }
  .unassigned {
    .badge-primary {
      background: ${ROTA_SHIFT};
      padding: 0.3rem 1.5rem;
    }
  }

  .warningMessage {
    color: ${ERR_COLOR_800};
  }
`;

export const ButtonsContainer = styled.div`
  .calendarView {
    margin-top: 0.75%;
    margin-bottom: 1.5%;

    .clientView {
      color: ${props => (props.calendarView === 'client' && !props.listView ? PURPLE : 'black')};
      border: 0;
      border-bottom: ${props =>
        props.calendarView === 'client' && !props.listView? `4px solid ${PURPLE}` : 0};
      background: transparent !important;
      font-weight: ${props =>
        props.calendarView === 'client' && !props.listView? 'bold' : 'normal'};
    }

    .carerView {
      color: ${props => (props.calendarView === 'team' && !props.listView? PURPLE : 'black')};
      border: 0;
      border-bottom: ${props =>
        props.calendarView === 'team' && !props.listView? `4px solid ${PURPLE}` : 0};
      background: transparent !important;
      font-weight: ${props =>
        props.calendarView === 'team' && !props.listView? 'bold' : 'normal'};
    }
    .runView {
      color: ${props => (props.calendarView === 'run' && !props.listView? PURPLE : 'black')};
      border: 0;
      border-bottom: ${props =>
        props.calendarView === 'run' && !props.listView? `4px solid ${PURPLE}` : 0};
      background: transparent !important;
      font-weight: ${props =>
        props.calendarView === 'run' && !props.listView? 'bold' : 'normal'};
    }
    .listView {
      color: ${props => (props.listView  ? PURPLE : 'black')};
      border: 0;
      border-bottom: ${props =>
        props.listView  ? `4px solid ${PURPLE}` : 0};
      background: transparent !important;
      font-weight: ${props =>
        props.listView  ? 'bold' : 'normal'};
    }
    .locationView {
      color: ${props => (props.calendarView === 'location' && !props.listView && !props.run ? PURPLE : 'black')};
      border: 0;
      border-bottom: ${props =>
        props.calendarView === 'location' && !props.listView && !props.run? `4px solid ${PURPLE}` : 0};
      background: transparent !important;
      font-weight: ${props =>
        props.calendarView === 'location' && !props.listView && !props.run? 'bold' : 'normal'};
  }
  }

  @media (max-width: ${MD}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }
`;

export const ToggleDiv = styled.div`
  display: flex;;
  .toggle-label {
    margin-left: 0.5%;
  }

  @media (max-width: ${MD}) {
    margin-bottom: 2%;
    font-size: smaller;
  }
  @media (min-width: ${LG}) {
    margin-bottom: 1.5%;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
 margin-right:0;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// export const IconWrapper = styled.div`
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
//   opacity: ${(props) => (props.disabled ? 0.5 : 1)};
//   transition: transform 0.2s ease-in-out;
//   pointer-events: ${(props) => (props.disabled ? "none" : "auto")}; /* Prevents click events */

//   &:hover {
//     transform: ${(props) => (props.disabled ? "none" : "scale(1.1)")};
//   }

//   svg {
//     font-size: ${(props) => props.size || 40}px;
//     color: ${(props) => props.color || PURPLE};
//   }
// `;

export const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: transform 0.2s ease-in-out;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    transform: ${(props) => (props.disabled ? "none" : "scale(1.1)")};
  }

   svg {
   font-size: ${(props) => props.size || 40}px;
   color: ${(props) => props.color || PURPLE};
   }

  /* Loader Circle (only visible when loading is true) */
  &::after {
    content: "";
    display: ${(props) => (props.loading ? "block" : "none")};
    position: absolute;
    width: 2.4em;
    height: 2.4em;
    border: 3px solid transparent;
    border-top: 3px solid ${PURPLE};
    border-radius: 50%;
    animation: ${spin} 1s linear infinite;
  }
`;