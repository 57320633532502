import { useModal } from "hooks/ModalHook";
import React, { createContext, useState, useContext } from "react";
import SessionWorning from "./SessionWarning";
import { ERROR_OUTLINE } from "utils/constants/icons";
import { TiWarningOutline } from "react-icons/ti";
import { SOFT_RED } from "shared/styles/constants/colors";

const SessionContext = createContext();

let setSessionExpiredGlobal = () => { }; // Global function
let setTitle = () => { };
export const SessionProvider = ({ children }) => {
  const {
    setValue: setIsSessionExpired,
    value: isSessionExpired,
    fullBind: fullBindSession,
  } = useModal(false);
  const [subTitle, setSubTitle] = useState('')
  setSessionExpiredGlobal = setIsSessionExpired
  setTitle = setSubTitle
  const ERROR_ICON = <TiWarningOutline color={SOFT_RED} />
  return (
    <SessionContext.Provider value={{ isSessionExpired, setIsSessionExpired, subTitle, setSubTitle }}>
      {children}
      {isSessionExpired && (

        <SessionWorning
          type={'warning'}
          icon={ERROR_ICON}
          setModalValue={setIsSessionExpired}
          fullBind={fullBindSession}
          subTitle={subTitle}
        // values={values}
        />

      )}
    </SessionContext.Provider>
  );
};

export const setSessionExpiry = (message) => {
  setSessionExpiredGlobal(true);
  setTitle(message)
};
