import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {
  PURPLE,
  DARK_VIOLET,
  GRAY_700,
  WHITE,
  PURPLE_GRAY_500,
  ERR_COLOR,
  GRAY_400,
} from 'shared/styles/constants/colors';
import {MD} from 'shared/styles/constants/resolutions';

export const AnimationStyle = /*css*/ `
  &:hover {
    background-color: ${PURPLE};
    text-decoration: none;

    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);

    -moz-transition: transform 0.05s ease-in-out;
    -webkit-transition: transform 0.05s ease-in-out;
    -ms-transition: transform 0.05s ease-in-out;
  }
  &:active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);

    -moz-transition: transform 0.05s ease-in-out;
    -webkit-transition: transform 0.05s ease-in-out;
    -ms-transition: transform 0.05s ease-in-out;
  }
`;

export const stylePrimaryButton = /*css*/ `
  background-color: ${PURPLE} ;
  font-size: 0.875rem;
  width: auto!important;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  -webkit-border-radius: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
  border-radius: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
  // -webkit-transition: all 0.3s ease-in-out;
  // -moz-transition: all 0.3s ease-in-out;
  // -ms-transition: all 0.3s ease-in-out;
  // -o-transition: all 0.3s ease-in-out;
  // transition: all 0.3s ease-in-out;

  &:focus {
    outline: none;
  }
  ${AnimationStyle}
`;

export const LinkStyled = styled(Link)`
  ${stylePrimaryButton}
  height: fit-content;
  &:hover {
    color: white;
  }
`;

function outlineButton(props) {
  let returnStyle = '';

  if (props.outline) {
    returnStyle = `
    background: transparent !important;
    border: 2px solid ${PURPLE};
    color: black !important;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
     `;
  }

  return returnStyle;
}

export const PrimaryButton = styled.button`
  min-width: ${props => (props.minWidth ? props.minWidth : 'auto')};
  color: ${props => (props.disabled ? GRAY_700 : WHITE)};
  ${stylePrimaryButton}
  margin-right:${props =>(props.marginRight ? props.marginRight : 0)};

  -webkit-border-radius: ${props =>(props.radius ? props.radius : '1.875rem 1.875rem 1.875rem 1.875rem')} ;
  border-radius: ${props =>(props.radius ? props.radius : '1.875rem 1.875rem 1.875rem 1.875rem')} ;

  svg {
    font-size: 1.2rem;
    vertical-align: text-bottom;
    margin-right: 0.25rem;
    margin-bottom: 0;
  }
  label {
    margin: 0;  
  }

  ${props => outlineButton(props)};
  ${({shadow}) => addShadow(shadow)};
`;

function addShadow(shadow) {
  let returnStyle = '';
  if (shadow) {
    returnStyle = `
    -webkit-box-shadow: 0 4px 7px -3px ${DARK_VIOLET};
    -moz-box-shadow: 0 4px 7px -3px ${DARK_VIOLET};
     box-shadow: 0 4px 7spx -3px ${DARK_VIOLET};
     `;
  }
  return returnStyle;
}

export const UploadInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const UploadInputLabel = styled.label`
  box-shadow: ${props => (props.disabled ? 'none!important' : 'inherith')};
  cursor: pointer;

  svg {
    font-size: 1.2rem;
    vertical-align: text-bottom;
    margin-right: 0.25rem;
  }

  ${stylePrimaryButton}
`;

export const PrimaryButtonForm = styled(PrimaryButton)`
  width: ${props => (props.width ? props.width + " !important" : '12.5rem')};
  ${props => changeButtonColor(props.bgcolor, props.disabled)};
  height:${props => (props.height ? props.height : null)};
`;

export const PermissionActionButton = styled.button`
  width: 65px;
  height: 30px;
  background-color: ${PURPLE}; /* You can change this to any color you prefer */
  color: white;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;

  &:hover {
    background-color: ${GRAY_400}; /* Darker shade for hover effect */
  }

  &:disabled {
    background-color: ${GRAY_400}; /* Gray color for disabled state */
    cursor: not-allowed;
  }
`;

function changeButtonColor(bgcolor, disabled) {
  if (disabled) {
    return `
    background-color: ${GRAY_400};
        &:hover {
          background-color: ${GRAY_400};
        }
    `;
  }

  switch (bgcolor) {
    case 'ERR_COLOR':
      return `
        background-color: ${ERR_COLOR};
        &:hover {
          background-color: ${ERR_COLOR};
        }
      `;
      
    case 'PURPLE_GRAY_500':
      return `
        background-color: ${PURPLE_GRAY_500};
        &:hover {
          background-color: ${PURPLE_GRAY_500};
        }
      `;
    default:
      return `background-color: ${PURPLE};`;
  }
}

export const FloatingMobileButton = styled(PrimaryButton)`
  min-width: 12.375rem;
  @media (max-width: ${MD}) {
    min-width: auto;
    z-index: 1;
    position: fixed;
    bottom: 4%;
    right: 4%;
    display: flex;
    align-items: center;
    width: 3.125rem !important;
    height: 3.125rem;
    border-radius: 50%;
    label {
      display: none;
    }
    svg {
      margin: 0;
    }
  }
`;

export const LoginButton = styled(PrimaryButton)`
  margin: 0.3125rem 0 2.5rem 0;
  width: 85%;
`;

export const ToggleWrapper = styled.div`
  position: relative;
  top:${props => props.top ? props.top : ""}
`;
export const ToggleLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const Toggle = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${ToggleLabel} {
    background: #6200f9;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const DownloadButton = styled(PrimaryButton)`
&:hover{
  cursor:pointer !important;
}
  min-width: 7.375rem;
  @media (max-width: ${MD}) {
    min-width: auto;
    z-index: 1;
    position: fixed;
    bottom: 4%;
    right: 4%;
    display: flex;
    align-items: center;
    width: 3.125rem !important;
    height: 3.125rem;
    border-radius: 50%;
    label {
      display: none;
    }
    svg {
      margin: 0;
    }
    
  }
`;

